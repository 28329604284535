import React from 'react';
import '../styles/ServicePackages.css';

const ServicePackages = () => {
  return (
    <div className="service-packages">
      <h2>Pacotes e Assinaturas</h2>
      <table className="packages-table">
        <thead>
          <tr>
            <th>Pacote</th>
            <th>Serviços Incluídos</th>
            <th>Preço</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Bronze (2 meses)</td>
            <td>Escolha de 1 serviço limitado (cabelo, manicure ou estética)</td>
            <td>
              Tratamentos capilares - R$ 1.000,00 / Manicure - R$ 500,00 / Estética - R$ 500,00
            </td>
          </tr>
          <tr>
            <td>Silver (4 meses)</td>
            <td>Escolha de 2 serviços ilimitados (cabelo e manicure, ou estética e cabelo)</td>
            <td>
              Combo 1 (Cabelo - Manicure) - R$ 800,00 / Combo 2 (Cabelo - Estética) - R$ 800,00 / Combo 3 (Manicure - Estética) - R$ 600,00
            </td>
          </tr>
          <tr>
            <td>Gold (6 meses)</td>
            <td>Escolha de 2 serviços ilimitados por um período mais longo</td>
            <td>
              Combo 1 (Cabelo - Manicure) - R$ 600,00 / Combo 2 (Cabelo - Estética) - R$ 600,00 / Combo 3 (Manicure - Estética) - R$ 400,00
            </td>
          </tr>
          <tr>
            <td>Platinum (9 meses)</td>
            <td>Todos os serviços inclusos de forma ilimitada (cabelo, manicure, estética etc.)</td>
            <td>R$ 800,00</td>
          </tr>
          <tr>
            <td>Diamond (12 meses)</td>
            <td>Todos os serviços inclusos de forma ilimitada por 12 meses</td>
            <td>R$ 500,00</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ServicePackages;